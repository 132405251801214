var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      (!_vm.ativaTrilhaConsultas && !_vm.liberaTelaSuperbox) ||
      (_vm.ativaTrilhaConsultas && _vm.tipoConsultaSelecionada == 'Modelos')
    )?_c('ResultadosModelos',{attrs:{"opcao_inicial":_vm.opcao_inicial,"modeloSelecionado":_vm.modeloSelecionado,"resultadoModelo":_vm.resultadoModelo,"consultasSelecionadas":_vm.consultasSelecionadas,"resultadoConsultaAdd":_vm.resultadoConsultaAdd,"terminouConsulta":_vm.terminouConsulta,"liberaBlocoAlerta":_vm.liberaBlocoAlerta,"modelos":_vm.modelos}}):_vm._e(),(
      (!_vm.ativaTrilhaConsultas && _vm.liberaTelaSuperbox) ||
      (_vm.liberaTelaSuperbox &&
        _vm.ativaTrilhaConsultas &&
        _vm.tipoConsultaSelecionada == 'Superbox')
    )?_c('telaSuperBox',{attrs:{"opcaoInicialSelecionada":_vm.opcao_inicial,"produtos":_vm.produtos,"campos":_vm.campos,"contexto":_vm.contexto,"liberaTelaSuperbox":_vm.liberaTelaSuperbox,"liberaConsultaSuperbox":_vm.liberaConsultaSuperbox}}):_vm._e(),(_vm.abreSelecaoConsultaCiclica)?_c('div',{staticClass:"overlay",on:{"click":function($event){_vm.abreSelecaoConsultaCiclica = false}}}):_vm._e(),(_vm.abreSelecaoConsultaCiclica)?_c('SelecaoConsultaAdicional',{attrs:{"documento":_vm.documentoConsultar,"tipo":_vm.tipoDocumentoConsultar}}):_vm._e(),(!_vm.liberaSpinner && _vm.consultasTrilha.length > 0)?_c('TrilhaConsultas'):_vm._e(),(_vm.liberaSpinner)?_c('div',{staticClass:"spinnerBG"},[_c('Loading',{staticClass:"spinner"})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }