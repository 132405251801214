import { render, staticRenderFns } from "./preenchimentoCamposModelos.vue?vue&type=template&id=eddc4a92&scoped=true&"
import script from "./preenchimentoCamposModelos.vue?vue&type=script&lang=js&"
export * from "./preenchimentoCamposModelos.vue?vue&type=script&lang=js&"
import style0 from "./preenchimentoCamposModelos.vue?vue&type=style&index=0&id=eddc4a92&prod&lang=scss&"
import style1 from "./preenchimentoCamposModelos.vue?vue&type=style&index=1&id=eddc4a92&prod&scoped=true&lang=scss&"
import style2 from "./preenchimentoCamposModelos.vue?vue&type=style&index=2&id=eddc4a92&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "eddc4a92",
  null
  
)

export default component.exports