<template>
  <div class="view-wrapper" v-if="liberaTela">
    <StepsModelos
      v-if="verificaRotasSteps()"
      :opcao_inicial="opcaoInicial"
      :modeloSelecionado="modeloSelecionado"
    />
    <div class="view">
      <router-view
        :produtos="produtos"
        :modelos="modelos"
        :campos="campos"
        :contexto="contexto"
        :opcao_inicial="opcaoInicial"
        :modeloSelecionado="modeloSelecionado"
        :resultadoModelo="resultadoModelo"
        :consultasSelecionadas="consultasSelecionadas"
        :resultadoConsultaAdd="resultadoConsultaAdd"
        :terminouConsulta="terminouConsulta"
        :liberaBlocoAlerta="liberaBlocoAlerta"
        :liberaTelaSuperbox="liberaTelaSuperbox"
        :liberaConsultaSuperbox="liberaConsultaSuperbox"
        @selecionaInicial="selecionaInicial"
        @selecionaModelo="selecionaModelo"
        @selecionaConsultas="selecionaConsultas"
        @selecionaTodasConsultas="selecionaTodasConsultas"
        @realizaConsultaModelos="realizaConsultaModelos"
        @realizaConsultaAdicional="realizaConsultaAdicional"
        @recuperaConsultaSelecionada="recuperaConsultaSelecionada"
        @resetContextos="resetContextos"
      />
    </div>
  </div>
</template>

<script>
import { criaCampos } from "@/helpers";
import jsonProdutos from "@/helpers/produtos.json";

import StepsModelos from "@/components/Visuais/StepsModelos.vue";
import { consultasService } from "@/services";
import { produtosStore } from "@/stores/produtos";
import { userPreferenceStore } from "@/stores/userPreference";
import { storeAlertasSuperBoxPJ } from "@/stores/superbox-alertas-pj.js";
import { storeAlertasSuperBox } from "@/stores/superbox-alertas.js";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";

export default {
  name: "NovaConsultaMod",

  components: {
    StepsModelos,
  },
  data() {
    return {
      liberaTela: false,
      opcaoInicial: "",
      modeloSelecionado: {},
      resultadoModelo: {},
      consultasSelecionadas: [],
      msgErro: "",
      resultadoConsultaAdd: {},
      terminouConsulta: false,
      liberaBlocoAlerta: false,

      liberaTelaSuperbox: false,
      liberaConsultaSuperbox: false,
      campos: [],
      contexto: [],

      novaConsultaSelecionada: {},
      produtosSelecionados: [],
    };
  },
  watch: {
    consultasTrilha: {
      handler(newVal, oldVal) {
        if (newVal.length > 1) {
          const novaConsulta = this.consultasTrilha.find(
            (consulta) => consulta.cod_agrupador == "loading"
          );

          if (novaConsulta) {
            this.novaConsultaSelecionada = novaConsulta;

            if (novaConsulta.consulta == "Modelos") {
              this.realizaConsultaCiclica(
                this.novaConsultaSelecionada.parametros
              );
            } else if (this.novaConsultaSelecionada.consulta == "Superbox") {
              this.realizaConsultaCiclicaSuperbox(
                this.novaConsultaSelecionada.parametros
              );
            }
          }
        }
      },
      deep: true,
    },

    ativaNovaConsultaMod(newVal, oldVal) {
      if (newVal != oldVal) {
        if (this.codigo_agrupador != this.trilhaSelecionada.cod_agrupador) {
          this.liberaTelaSuperbox = false;
          this.recuperaConsultaSelecionada();
        }
      }
    },
  },

  methods: {
    recuperaConsultaSelecionada() {
      this.terminouConsulta = false;

      var selecao = this.consultasTrilha.find(
        (consulta) =>
          consulta.cod_agrupador == this.trilhaSelecionada.cod_agrupador
      );

      if (this.trilhaSelecionada.consulta == "Modelos") {
        if (this.codigo_agrupador != selecao.cod_agrupador) {
          this.liberaTelaSuperbox = false;
          this.codigo_agrupador = selecao.cod_agrupador;
          this.opcaoInicial = selecao.historico.tipo_pessoa;
          this.resultadoModelo = selecao.historico.resultadoModelo;
          this.consultasSelecionadas = selecao.historico.consultasSelecionadas;
          this.resultadoConsultaAdd = selecao.historico.resultadoConsultaAdd;
          this.liberaBlocoAlerta = selecao.historico.liberaBlocoAlerta;
          this.modeloSelecionado = selecao.historico.modeloSelecionado;
        }
      } else if (this.trilhaSelecionada.consulta == "Superbox") {
        this.opcaoInicial =
          selecao.tipo_pessoa == "PF" ? "Pessoa Física" : "Pessoa Jurídica";
        this.liberaConsultaSuperbox = false;
        this.contexto = selecao.historico.contexto;
        this.consultaErros = selecao.historico.consultaErros;
        this.listaBlocos = selecao.historico.listaBlocos;
        this.codigo_agrupador = selecao.cod_agrupador;
        this.liberaTelaSuperbox = true;
        this.produtosSelecionados = selecao.historico.produtosSelecionados;
      }

      this.terminouConsulta = true;
      this.atualizaTelaResultadosModelos();
    },

    reset() {
      this.resultadoModelo = {};
      this.resultadoConsultaAdd = {};
    },

    selecionaModelo(modelo) {
      if (modelo == this.modeloSelecionado) {
        this.modeloSelecionado = {};
      } else {
        this.modeloSelecionado = modelo;
      }
    },

    selecionaConsultas(consulta) {
      if (consulta != undefined) {
        const index = this.consultasSelecionadas.findIndex(
          (item) => item.Valor === consulta.Valor
        );

        if (index === -1) {
          this.consultasSelecionadas.push(consulta);
        } else {
          this.consultasSelecionadas.splice(index, 1);
        }
      } else {
        this.consultasSelecionadas = [];
      }

      // const preferenciaNome = "PortalOutbox_consultaAdicionaisModelos";
      // const preferenciasString = this.consultasSelecionadas
      //   .map((produto) => {
      //     return `${produto.Nome}`;
      //   })
      //   .join(";");

      // this.substituiPreferencia(preferenciaNome, preferenciasString);
    },

    selecionaTodasConsultas(check, consultasAdicionais) {
      if (check) {
        this.consultasSelecionadas = [];
      } else {
        this.consultasSelecionadas = [...consultasAdicionais];
      }
    },

    async realizaConsultaModelos(resultado) {
      this.terminouConsulta = false;
      this.reset();
      this.resetAlertasPj();

      const blocoAlerta = this.consultasSelecionadas.some((consulta) =>
        consulta.Nome.includes("Alertas Gerais")
      );
      if (blocoAlerta) {
        await this.geraCodigoAgrupadorMetadados("consulta_modelo;bloco_alerta");
      } else {
        await this.geraCodigoAgrupadorMetadados("consulta_modelo");
      }

      const mapeamento = {
        "Pessoa Física": "pessoa_fisica",
        "Pessoa Jurídica": "pessoa_juridica",
      };
      const mapeamentoSigla = {
        "Pessoa Física": "PF",
        "Pessoa Jurídica": "PJ",
      };

      let tipoPessoa = mapeamento[this.opcaoInicial];
      let tipoPessoaSigla = mapeamentoSigla[this.opcaoInicial];

      const objetoConsulta = {
        cep: "",
        tipo_pessoa: tipoPessoa,
        cpf_cnpj: "",
        cod_ref: this.modeloSelecionado.Valor,
        data_ref: resultado.data_ref || "",
        modo_resultado: "codigo",
        parametros_adicionais: {},
        identificador_etiqueta_primaria: "PRD",
        identificadores_etiquetas_secundarias: "",
        cod_agrupador: this.codigo_agrupador,
      };

      for (const chave in resultado) {
        if (chave === "cpf" || chave === "cnpj") {
          objetoConsulta["cpf_cnpj"] = resultado[chave];
        } else {
          if (objetoConsulta.hasOwnProperty(chave)) {
            objetoConsulta[chave] = resultado[chave]
              ? resultado[chave].trim()
              : resultado[chave];
          } else {
            objetoConsulta.parametros_adicionais[chave] = resultado[chave]
              ? resultado[chave].trim()
              : resultado[chave];
          }
        }

        if (chave == "data_ref") {
          this.dataDaConsulta = resultado[chave];
        }
      }

      await consultasService
        .realizaConsultaModelo(objetoConsulta)
        .then((resultadoModelo) => {
          this.resultadoModelo = resultadoModelo;
        });

      if (this.resultadoModelo.erro) {
        this.msgErro = this.resultadoModelo.erro;
      }

      if (this.consultasSelecionadas.length > 0) {
        await this.realizaConsultaAdicional(resultado);
      }

      const documento = objetoConsulta.cpf_cnpj;
      const consulta = {
        id: 1,
        origem: null,
        cod_agrupador: this.codigo_agrupador,
        tipo_pessoa: tipoPessoaSigla,
        consulta: "Modelos",
        modeloSelecionado: this.modeloSelecionado,
        consultasSelecionadas: this.consultasSelecionadas,
        parametros: (() => {
          if (documento.length === 18) {
            return {
              cnpj: documento,
              cep: objetoConsulta.cep,
              data_ref: this.dataDaConsulta,
            };
          } else if (documento.length === 14) {
            return {
              cpf: documento,
              cep: objetoConsulta.cep,
              data_ref: this.dataDaConsulta,
            };
          } else {
            return {};
          }
        })(),
        // parametros: {
        //   cnpj: documento,
        //   cep: objetoConsulta.cep,
        // },
        historico: {
          tipo_pessoa: this.opcaoInicial,
          resultadoModelo: this.resultadoModelo,
          consultasSelecionadas: this.consultasSelecionadas,
          resultadoConsultaAdd: this.resultadoConsultaAdd,
          liberaBlocoAlerta: this.liberaBlocoAlerta,
          modeloSelecionado: this.modeloSelecionado,
        },
      };

      this.consultasTrilha.push(consulta);
      this.trilhaSelecionada = consulta;

      this.terminouConsulta = true;

      if (this.consultasSelecionadas.length > 0) {
        await this.realizaConsultaAdicionalKYC(resultado);
      }
    },

    async realizaConsultaCiclica(resultado) {
      const printCodAgr = this.codigo_agrupador;

      this.terminouConsulta = false;
      this.resetAlertasPj();
      this.resetAlertasPf();
      this.reset();
      this.consultasSelecionadas = [];
      this.liberaBlocoAlerta = false;

      this.consultasSelecionadas =
        this.novaConsultaSelecionada.consultasSelecionadas;

      const blocoAlerta = this.consultasSelecionadas.some((consulta) =>
        consulta.Nome.includes("Alertas Gerais")
      );

      this.resetaCodigoAgrupador();
      if (blocoAlerta) {
        await this.geraCodigoAgrupadorMetadados(
          `consulta_modelo;bloco_alerta;origemTrilha=${printCodAgr}`
        );
      } else {
        await this.geraCodigoAgrupadorMetadados(
          `consulta_modelo;origemTrilha=${printCodAgr}`
        );
      }

      var novaConsulta = this.consultasTrilha.find(
        (obj) => obj.cod_agrupador === "loading"
      );

      if (novaConsulta) {
        novaConsulta.cod_agrupador = this.codigo_agrupador;
        this.modeloSelecionado = novaConsulta.modeloSelecionado;
      }

      const mapeamento = {
        PF: "pessoa_fisica",
        PJ: "pessoa_juridica",
      };

      const mapeamentoSigla = {
        PF: "Pessoa Física",
        PJ: "Pessoa Jurídica",
      };

      this.opcaoInicial = mapeamentoSigla[novaConsulta.tipo_pessoa];

      const objetoConsulta = {
        cep: "",
        tipo_pessoa: mapeamento[novaConsulta.tipo_pessoa],
        cpf_cnpj: "",
        cod_ref: novaConsulta.modeloSelecionado.Valor,
        data_ref: this.dataDaConsulta,
        modo_resultado: "codigo",
        parametros_adicionais: {},
        identificador_etiqueta_primaria: "PRD",
        identificadores_etiquetas_secundarias: "",
        cod_agrupador: this.codigo_agrupador,
      };

      for (const chave in resultado) {
        if (chave === "cpf" || chave === "cnpj") {
          objetoConsulta["cpf_cnpj"] = resultado[chave];
        } else {
          if (objetoConsulta.hasOwnProperty(chave)) {
            objetoConsulta[chave] = resultado[chave]
              ? resultado[chave].trim()
              : resultado[chave];
          } else {
            objetoConsulta.parametros_adicionais[chave] = resultado[chave]
              ? resultado[chave].trim()
              : resultado[chave];
          }
        }
      }

      await consultasService
        .realizaConsultaModelo(objetoConsulta)
        .then((resultadoModelo) => {
          this.resultadoModelo = resultadoModelo;
        });

      if (this.resultadoModelo.erro) {
        this.msgErro = this.resultadoModelo.erro;
      }

      if (this.consultasSelecionadas.length > 0) {
        await this.realizaConsultaAdicional(resultado);
      }

      var historico = {};
      historico = {
        cod_agrupador: this.codigo_agrupador,
        tipo_pessoa: this.opcaoInicial,
        resultadoModelo: this.resultadoModelo,
        consultasSelecionadas: this.consultasSelecionadas,
        resultadoConsultaAdd: this.resultadoConsultaAdd,
        liberaBlocoAlerta: this.liberaBlocoAlerta,
        modeloSelecionado: this.modeloSelecionado,
      };

      novaConsulta.historico = historico;

      this.trilhaSelecionada = this.consultasTrilha.find(
        (consulta) => consulta.cod_agrupador == this.codigo_agrupador
      );

      this.terminouConsulta = true;
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1000);
    },

    async realizaConsultaCiclicaSuperbox(resultado) {
      this.liberaTelaSuperbox = false;
      const printCodAgr = this.codigo_agrupador;

      this.resetContextos();
      await this.geraCodigoAgrupadorMetadados(
        `consulta_superbox;origemTrilha=${printCodAgr}`
      );

      this.selecionaTodosSuperBox();
      const produtosSelecionados = this.produtos.filter((produto) => {
        return produto.Selecionado;
      });

      this.campos = Object.keys(resultado).map((key) => {
        return {
          Nome: key.toUpperCase(),
          Valor: resultado[key],
        };
      });

      var contexto_idx = this.contexto.length;
      this.contexto[contexto_idx] = {
        id: contexto_idx,
        obrigatorio: {},
        requisitados: {},
        resultados: {},
      };

      produtosSelecionados.forEach((produto) => {
        this.$set(this.contexto[contexto_idx].requisitados, produto.Box, true);
      });

      this.liberaConsultaSuperbox = true;
      this.liberaTelaSuperbox = true;

      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 1000);
    },

    selecionaTodosSuperBox() {
      this.produtos.forEach((produto) => {
        if (
          !produto.Selecionado &&
          produto.Classificacao == "Superbox" &&
          produto.TipoProduto == "PF"
        ) {
          this.$set(produto, "Selecionado", true);
        } else if (produto.Selecionado && produto.Classificacao != "Superbox") {
          this.$set(produto, "Selecionado", false);
        }
      });
    },

    resetContextos() {
      this.contexto = [];
      this.campos = [];
      this.resetaCodigoAgrupador();
      this.liberaTelaSuperbox = false;
      this.liberaConsultaSuperbox = false;

      this.novaConsultaSelecionada = {};
      this.produtosSelecionados = [];
    },

    async realizaConsultaAdicional(resultado) {
      const mapeamento = {
        "Pessoa Física": "pessoa_fisica",
        "Pessoa Jurídica": "pessoa_juridica",
      };
      let tipoPessoa = mapeamento[this.opcaoInicial];
      for (const i in this.consultasSelecionadas) {
        if (
          this.consultasSelecionadas[i].Valor != "BOXSUPPJ009" &&
          this.consultasSelecionadas[i].Valor != "BOXKYC"
        ) {
          var objetoConsulta = {};
          objetoConsulta["cpf_cnpj"] =
            tipoPessoa == "pessoa_fisica" ? resultado.cpf : resultado.cnpj;
          objetoConsulta["cep"] = resultado.cep;
          objetoConsulta["cod_ref"] = this.consultasSelecionadas[i].Valor;
          objetoConsulta["tipo_pessoa"] = tipoPessoa;
          objetoConsulta["modo_resultado"] = "codigo";
          objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
          objetoConsulta["data_ref"] = resultado.data_ref || "";

          await consultasService
            .realizaConsultaBox(objetoConsulta)
            .then((resultado) => {
              this.resultadoConsultaAdd[this.consultasSelecionadas[i].Valor] =
                resultado;
            });
          this.liberaBlocoAlerta = false;
        } else if (this.consultasSelecionadas[i].Valor == "BOXSUPPJ009") {
          this.liberaBlocoAlerta = true;
        }
      }
    },

    async realizaConsultaAdicionalKYC(resultado) {
      const mapeamento = {
        "Pessoa Física": "pessoa_fisica",
        "Pessoa Jurídica": "pessoa_juridica",
      };
      let tipoPessoa = mapeamento[this.opcaoInicial];
      for (const i in this.consultasSelecionadas) {
        if (this.consultasSelecionadas[i].Valor == "BOXKYC") {
          var boxKYC = ["BOXKYC001", "BOXKYC002", "BOXKYC003", "BOXKYC004"];
          await Promise.all(
            boxKYC.map(async (codRef) => {
              var objetoConsulta = {};
              objetoConsulta["cpf_cnpj"] =
                tipoPessoa == "pessoa_fisica" ? resultado.cpf : resultado.cnpj;
              objetoConsulta["cep"] = resultado.cep;
              objetoConsulta["cod_ref"] = codRef;
              objetoConsulta["tipo_pessoa"] = tipoPessoa;
              objetoConsulta["modo_resultado"] = "codigo";
              objetoConsulta["cod_agrupador"] = this.codigo_agrupador;
              objetoConsulta["data_ref"] = resultado.data_ref || "";

              const resultadoConsulta =
                await consultasService.realizaConsultaBox(objetoConsulta);

              this.$set(this.resultadoConsultaAdd, codRef, resultadoConsulta);
            })
          );
        }
      }
    },

    verificaRotasSteps() {
      var rota = this.$route.fullPath.slice(-1);
      if (parseInt(rota) < 3) {
        return true;
      } else {
        return false;
      }
    },

    selecionaInicial(opcao) {
      this.opcaoInicial = opcao;
    },

    ...mapActions(produtosStore, [
      "getProdutos",
      "getModelos",
      "criaCodigoAgrupador",
      "resetaCodigoAgrupador",
      "resetStore",
      "geraCodigoAgrupadorMetadados",
    ]),

    ...mapActions(storeAlertasSuperBox, ["resetAlertasPf"]),
    ...mapActions(storeAlertasSuperBoxPJ, ["resetAlertasPj"]),

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaGeral",
      "createPreferencia",
      "substituiPreferencia",
    ]),

    ...mapActions(dadosConsultaAddStore, [
      "resetConsutaAdd",
      "atualizaTelaResultadosModelos",
    ]),

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  computed: {
    ...mapWritableState(produtosStore, {
      produtos: "produtos",
      modelos: "modelos",
      codigo_agrupador: "codigo_agrupador",
      codigo_agrupador_box: "codigo_agrupador_box",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      ativaNovaConsultaMod: "ativaNovaConsultaMod",
      consultasTrilha: "consultasTrilha",
      trilhaSelecionada: "trilhaSelecionada",
      dataDaConsulta: "dataDaConsulta",
    }),
  },

  async mounted() {
    const contexto = {
      codigo_agrupador: "",
      rota: this.$route.path,
      nome: this.$route.name,
      descricao: "",
    };

    this.recebeContexto(contexto);

    this.resetStore();
    this.resetConsutaAdd();
    this.resetContextos();
    this.resetAlertasPf();
    this.resetAlertasPj();

    await this.getModelos();
    this.liberaTela = true;
    await this.getProdutos();

    this.campos = criaCampos.montaCampos(jsonProdutos);
  },

  beforeDestroy() {
    this.contexto = [];
  },
};
</script>

<style scoped>
.view-wrapper {
  margin-top: 10px;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.absolute {
  display: none;
}

.view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1024px) {
  .view-wrapper {
    margin-top: 30px;
    gap: 30px;
  }
  .absolute {
    display: block;
    position: absolute;
    height: 30px;
    width: 50px;
    top: 20px;
    left: 100px;
  }
}
</style>
